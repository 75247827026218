import React from 'react';
import { Link } from "gatsby";

const OurPeopleCardEmail = (props) => {
    const btnState = { title: props.memberName, email: props.email }
    
    return (
        <Link to={`/about/contact-member/`} state={btnState ? btnState : null}>
            {props.emailLabel}
        </Link>
    );
}

export default OurPeopleCardEmail;