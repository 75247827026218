
import React from 'react';

import FacebookIcon from '@icons/facebook.inline.svg';

const OurPeopleCardFacebook = (props) => {
    return (
        <a href={props.socialLink} target="_blank" rel="noreferrer" aria-label="Facebook page">
            <FacebookIcon />
        </a>
    );
}

export default OurPeopleCardFacebook;