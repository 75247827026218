
import React from 'react';

import LinkedInIcon from '@icons/linkedIn.inline.svg';

const OurPeopleCardLinkedIn = (props) => {
    return (
        <a href={props.socialLink} target="_blank" rel="noreferrer" aria-label="LinkedIn page">
            <LinkedInIcon />
        </a>
    );
}

export default OurPeopleCardLinkedIn;