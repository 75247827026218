
import React from 'react';

const OurPeopleCardBio = (props) => {
    return (
        <a href={`${props.bioLink}/`}>
            {props.bioLabel}
        </a>
    );
}

export default OurPeopleCardBio;