import React from 'react';
import { Link } from 'gatsby'

const OurPeopleCardImg = (props) => {
    const image_url = props.imgNode?.internal?.description.replace("File ","").replace('"','').replace('"','');
    return (
        <div className={props.imgClass}>
            {props.bioLink && props.imgNode && <Link to={`${props.bioLink}/`}><img alt={'People'} size={'ourPeopleImg'} src={image_url} />
            </Link>}
            {!props.bioLink && props.imgNode && <img alt={'People'} size={'ourPeopleImg'} src={image_url} />}
        </div>
    );
}

export default OurPeopleCardImg;
